import { UPDATE_ORDER_ITEMS, UPDATE_MENU_ITEMS, UPDATE_ORDER_TOTAL } from "../constants/action-types";
const initialState = {
  orderItems: [],
  menuItems: [],
  orderSubtotal: 0.00,
  orderTax: 0.00,
  orderTotal: 0.00,
};
export default (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_ORDER_ITEMS:
        let orderSubtotal = 0.00;
        let orderTax = 0.00;
        let orderTotal = 0.00;
        action.payload.forEach(orderItem => {
            const filteredMenuItem = state.menuItems.find(menuItem => {
                return menuItem.item === orderItem.item;
            });
            console.log({filteredMenuItem});
            console.log({orderItem});
            let itemTotal = 0.00;
            if (orderItem.size === 'EMPTY') {
              itemTotal += parseFloat(filteredMenuItem.pricing[0].price.split('$')[1]) * orderItem.quantity; 
              //orderTotal += parseInt(filteredMenuItem.pricing[0].price.split('$')[1]) * orderItem.quantity;
            } else {
              const filteredPrice = filteredMenuItem.pricing.find(priceInfo => {
                return priceInfo.size === orderItem.size;
              });
              itemTotal += parseFloat(filteredPrice.price.split('$')[1]) * orderItem.quantity;
              //orderTotal += parseInt(filteredPrice.price.split('$')[1]) * orderItem.quantity;
            }

            if (orderItem.isDairyFree) {
              itemTotal += (2.0 * orderItem.quantity);
              //orderTotal += (2.0 * orderItem.quantity);
            }
            const itemTax = filteredMenuItem.taxable ? itemTotal * 0.08 : 0.00;

            orderSubtotal += itemTotal;
            orderTax += itemTax;
            orderTotal = orderSubtotal + orderTax;
            // orderSubtotal = parseFloat(orderSubtotal).toFixed(2);
            // orderTax = parseFloat(orderTax).toFixed(2);
            // orderTotal = parseFloat(orderTotal).toFixed(2);
            console.log({itemTotal});
            console.log({orderSubtotal});
            console.log({orderTax});
            console.log({orderTotal});
        });
        return {
          ...state,
          orderItems: action.payload,
          orderSubtotal,
          orderTax,
          orderTotal
        };
      case UPDATE_MENU_ITEMS:
        return {
          ...state,
          menuItems: action.payload
        };
        case UPDATE_ORDER_TOTAL:
        return {
          ...state,
          ordersStartDate: action.payload
        };
      default:
        return state;
    }
  };