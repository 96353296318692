import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import MenuItem from '../components/MenuItem';
import { updateMenuItems } from '../actions/index';
import {
  getMonthLabel,
  getNumberPostfix,
  getDayLabel
} from '../utils/time';
import {
  PRIMARY_COLOR,
} from '../theme/colors';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  titleSection: {
    marginLeft: 12,
    marginBottom: 4,
    marginTop: 30
  },
  dividerStyle: {
      background: "primary",
      height: 2,
      backgroundColor: "primary"
  },
  newMenuItem: {
    marginTop: 15,
    marginLeft: 14,
    float: 'left'
  },
  MenuSection: {
    display: 'flex', 
    flexDirection: 'column', 
    marginLeft: 10,
    marginRight: 10,
  },
  MenuItemSeparator: {
    height: '1rem'
  },
  OrdersPlacedByText: {
    marginTop: '1rem',
  },
  SelectYourItemsText: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  SelectYourItemsTextLg: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '15%'
  },
  MenuSectionType: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  SectionDividerLeft: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    width: '50%',
    height: '1px',
    background: PRIMARY_COLOR
  },
  SectionDividerRight: {
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    width: '50%',
    height: '1px',
    background: PRIMARY_COLOR
  },
  SectionDividerLeftSmall: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    width: '50%',
    height: '1px',
    background: PRIMARY_COLOR
  },
  SectionDividerRightSmall: {
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    width: '50%',
    height: '1px',
    background: PRIMARY_COLOR
  }
});

const listMenuItems = `query listMenuItems($limit: Int, $filter: ModelMenuItemFilterInput) {
    listMenuItems(limit:$limit, filter:$filter) {
      items{
        id
        item
        pricing {
          price
          size
          feeds
          barcodeFileName
          hasStock
          remainingStock
        }
        taxable
        description
        category
        available
        canBeDairyFree
        imageName
        hasStock
        remainingStock
      }
    }
  }`

class Menu extends React.Component {

  state = {
    menu: [],
    pies: [],
    cakes: [],
    breadsAndRolls: [],
    cookies: [],
    pastries: []
  }

  async componentDidMount() {
    //console.log('Print');
    //Analytics.record('Amplify_CLI');
    const menu = await API.graphql(graphqlOperation(listMenuItems, {limit: 50, filter: {available: {eq: true}}}));
    console.log(menu.data.listMenuItems.items);
    const pies = [];
    const cakes = [];
    const breadsAndRolls = [];
    const cookies = [];
    const pastries = [];
    menu.data.listMenuItems.items.forEach(menuItem => {
      if (menuItem.available) {
        if (menuItem.category === 'Pie') {
          pies.push(menuItem);
        } else if (menuItem.category === 'Cake') {
          cakes.push(menuItem);
        } else if (menuItem.category === 'Breads & Rolls') {
          breadsAndRolls.push(menuItem);
        } else if (menuItem.category === 'Cookies') {
          cookies.push(menuItem);
        } else if (menuItem.category === 'Pastries') {
          pastries.push(menuItem);
        }
      }
    })

    //console.log({menu});
    this.setState({menu: menu.data.listMenuItems.items, pies, cakes, breadsAndRolls, cookies, pastries});
    this.props.updateMenuItems(menu.data.listMenuItems.items);
  }

  render() {
  //const { orders } = this.props;
    const { classes, ordersEndedDate } = this.props;
    //console.log(this.props);
    const cakesEnabled = this.state.cakes.length > 0;
    const cookiesEnabled = this.state.cookies.length > 0;
    const piesEnabled = this.state.pies.length > 0;
    const pastriesEnabled = this.state.pastries.length > 0;
    const breadsAndRollsEnabled = this.state.breadsAndRolls.length > 0;
    return (
      <div className={classes.MenuSection}>
        <Typography align='center' color='primary' variant="h5" gutterBottom className={classes.OrdersPlacedByText}>
          {`All orders must be placed by ${getDayLabel(ordersEndedDate.day())}, ${getMonthLabel(ordersEndedDate.month())} ${ordersEndedDate.date()}${getNumberPostfix(ordersEndedDate.date())} at ${ordersEndedDate.format('h')}pm`}
        </Typography>   
        <Typography align='center' color='primary' variant="body1" gutterBottom className={classes.SelectYourItemsText}>
          Select your items
        </Typography>
        {cakesEnabled ? (
        <div className={classes.MenuSectionType}>
        <div className={classes.SectionDividerLeft}/>
        <Typography align='center' color='primary' variant="body1" gutterBottom className={classes.SelectYourItemsText}>
          Cakes
        </Typography>  
        <div className={classes.SectionDividerRight}/>
        </div>
        ) : null}
        {this.state.cakes.map(cakeItem => {
          console.log({cakeItem});
          if (cakeItem.available && (!cakeItem.hasStock || (cakeItem.hasStock && cakeItem.remainingStock > 0))) {
            return (
              <div key={cakeItem.id}>
              <MenuItem itemInfo={cakeItem}/>
              <div className={classes.MenuItemSeparator}/>
              </div>
            );
          }
          return null;
        })}
        {cookiesEnabled ? (
        <div className={classes.MenuSectionType}>
        <div className={classes.SectionDividerLeft}/>
        <Typography align='center' color='primary' variant="body1" gutterBottom className={classes.SelectYourItemsTextLg}>
          Cookies
        </Typography>  
        <div className={classes.SectionDividerRight}/>
        </div>
        ) : null}
        {this.state.cookies.map(cookiesItem => {
          //console.log({breadsAndRollsItem});
          if (cookiesItem.available && (!cookiesItem.hasStock || (cookiesItem.hasStock && cookiesItem.remainingStock > 0))) {
            return (
              <div key={cookiesItem.id}>
              <MenuItem itemInfo={cookiesItem}/>
              <div className={classes.MenuItemSeparator}/>
              </div>
            );
          }
          return null;
        })}
        {piesEnabled ? (
        <div className={classes.MenuSectionType}>
        <div className={classes.SectionDividerLeft}/>
        <Typography align='center' color='primary' variant="body1" gutterBottom className={classes.SelectYourItemsText}>
          Pies
        </Typography>  
        <div className={classes.SectionDividerRight}/>
        </div>
        ) : null}
        {this.state.pies.map(pieItem => {
          //console.log({pieItem});
          if (pieItem.available && (!pieItem.hasStock || (pieItem.hasStock && pieItem.remainingStock > 0))) {
            return (
              <div key={pieItem.id}>
              <MenuItem itemInfo={pieItem}/>
              <div className={classes.MenuItemSeparator}/>
              </div>
            );
          }
          return null;
        })}
        {pastriesEnabled ? (
        <div className={classes.MenuSectionType}>
        <div className={classes.SectionDividerLeft}/>
        <Typography align='center' color='primary' variant="body1" gutterBottom className={classes.SelectYourItemsText}>
          Pastries
        </Typography>  
        <div className={classes.SectionDividerRight}/>
        </div>
        ) : null}
        {this.state.pastries.map(pastryItem => {
          //console.log({pieItem});
          if (pastryItem.available && (!pastryItem.hasStock || (pastryItem.hasStock && pastryItem.remainingStock > 0))) {
            return (
              <div key={pastryItem.id}>
              <MenuItem itemInfo={pastryItem}/>
              <div className={classes.MenuItemSeparator}/>
              </div>
            );
          }
          return null;
        })}
        {breadsAndRollsEnabled ? (
        <div className={classes.MenuSectionType}>
        <div className={classes.SectionDividerLeft}/>
        <Typography align='center' color='primary' variant="body1" gutterBottom className={classes.SelectYourItemsTextLg}>
          {'Breads & Rolls'}
        </Typography>  
        <div className={classes.SectionDividerRight}/>
        </div>
        ) : null}
        {this.state.breadsAndRolls.map(breadsAndRollsItem => {
          //console.log({breadsAndRollsItem});
          if (breadsAndRollsItem.available && (!breadsAndRollsItem.hasStock || (breadsAndRollsItem.hasStock && breadsAndRollsItem.remainingStock > 0))) {
            return (
              <div key={breadsAndRollsItem.id}>
              <MenuItem itemInfo={breadsAndRollsItem}/>
              <div className={classes.MenuItemSeparator}/>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
} // end Menu

Menu.propTypes = {
  //classes: PropTypes.object.isRequired,
  //orders: PropTypes.array.isRequired,
};

export default connect(null, { updateMenuItems })(withStyles(styles)(Menu));