import moment from 'moment';
const now = moment();
const currentMonth = now.month();
let PRIMARY_COLOR = '#A364AC';
if (currentMonth === 11) {
  PRIMARY_COLOR = '#0D8515';
} else if ([9, 10].includes(currentMonth)) {
  PRIMARY_COLOR = '#A57300';
} else {
  PRIMARY_COLOR = '#A364AC';
}

export {PRIMARY_COLOR};
