export const getMonthLabel = (monthNumber) => {
  let month = 'January';
  switch(monthNumber) {
    case 0:
      month = 'January';
      break;
    case 1:
      case 0:
      month = 'February';
      break;
    case 2:
      month = 'March';
      break;
    case 3:
      month = 'April';
      break;
    case 4:
      month = 'May';
      break;
    case 5:
      month = 'June';
      break;
    case 6:
      month = 'July';
      break;
    case 7:
      month = 'August';
      break
    case 8:
      month = 'September';
      break;
    case 9:
      month = 'October';
      break;
    case 10:
      month = 'November';
      break;
    case 11:
      month = 'December';
      break;
  }
  return month;
}

export const getNumberPostfix = (date) => {
  if ([1, 21, 31].includes(date)) {
    return 'st';
  } else if ([2, 22].includes(date)) {
    return 'nd'
  } else if ([3, 23].includes(date)) {
    return 'rd';
  } else if ([4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 25, 26, 27, 28, 29, 30]) {
    return 'th';
  }
}

export const getDayLabel = (day) => {
  switch(day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
  }
}