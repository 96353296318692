import { 
    UPDATE_ORDER_ITEMS,
    UPDATE_MENU_ITEMS,
    UPDATE_ORDER_TOTAL
} from "../constants/action-types";

export const updateOrderItems = (orderItems) => (
    {
        type: UPDATE_ORDER_ITEMS,
        payload: orderItems
    }
);

export const updateOrderTotal = (orderTotal) => (
    {
        type: UPDATE_ORDER_TOTAL,
        payload: orderTotal
    }
);

export const updateMenuItems = (menuItems) => (
    {
        type: UPDATE_MENU_ITEMS,
        payload: menuItems
    }
);

