import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { S3Image } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { updateOrderItems } from '../actions/index';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
    ItemImage: {
      width: 256,
    },
    MenuItemBlock: {
      display: 'flex',
    },
    MenuItemInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10
    },
    MenuItemPricing: {
      display: 'flex',
      marginLeft: 5,
      alignItems: 'center'
    },
    MenuItemTitleAndPrice: {
      display: 'flex'
    },
    QuantityAndSizeSelect: {
      display: 'flex',
      float: 'left'
    }, 
    FeedsTextPlaceholder: {
      height: '1.5rem'
    },
    DeleteItemButton: {
      color: 'white',
      backgroundColor: '#ff0030',
      width: '7rem',
    },
    QuantityAndSizeSpacing: {
      width: '1rem'
    }
  });
  
  const quantities = [{ value: 0, label: '0' }, { value: 1, label: '1' }, { value: 2, label: '2' },
  { value: 3, label: '3' }, { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' },
  { value: 7, label: '7' }, { value: 8, label: '8' }, { value: 9, label: '9' }, { value: 10, label: '10' }];

  class OrderItem extends React.Component {
  
    state = {
      selected: [],
      dialogOpen: false,
      deleteConfirmationOpen: false,
      quantity: 0,
      size: 'EMPTY',
      isDairyFree: false,
      ganacheTopping: false,
    }
  
    async componentDidMount() {
      //console.log('Print');
      //Analytics.record('Amplify_CLI');
    //   //console.log(this.props.itemInfo);
    //   if (this.props.itemInfo.pricing.length > 0) {
    //     if (this.props.itemInfo.pricing[0].size !== 'EMPTY') {
    //       this.setState({size: this.props.itemInfo.pricing[0].size});
    //     }
    //   }
    }
    // handleClickOpen = () => {
    //   const menuItemDetails = {
    //     item: '',
    //     pricing: [],
    //     description: '',
    //     category: '',
    //     available: true,
    //     canBeDairyFree: false,
    //     imageName: ''
    //   };
    //   this.props.toggleMenuItemDialog({menuItemDialogOpen: true, existingMenuItemOpened: false, menuItemInfo: menuItemDetails});
    // };
  
    renderPricing(pricing, menuItemPricingStyle) {
      let pricingString = '';
      pricing.forEach((price, priceIndex) => {
        //console.log({priceIndex});
        if (price.size !== 'EMPTY') {
          if (priceIndex === 0) {
            pricingString = `${price.price} - ${price.size}`;
          } else {
            pricingString = pricingString.concat(' | ', `${price.price} - ${price.size}`);
          }
        } else {
          pricingString = `${price.price}`;
        }
        //console.log({pricingString});
      });
      return (
        <Typography className={menuItemPricingStyle} align='left' variant="caption" gutterBottom>
          {pricingString}
        </Typography>
      )
    } // end renderPricing

    renderFeedsText(pricing, feedsTextPlaceholder) {
      let feedsString = '';
      pricing.forEach((price, priceIndex) => {
        if (price.size !== 'EMPTY') {
          if (priceIndex === 0) {
            feedsString = `${price.size} - ${price.feeds} people`;
          } else {
            feedsString = feedsString.concat(' | ', `${price.size} - ${price.feeds} people`);
          }
        } 
      });
      if (feedsString === '') {
        return (<div className={feedsTextPlaceholder} />);
      }
      return (
        <Typography align='left' variant="body2" gutterBottom>
          {feedsString}
        </Typography>
      )
    }

    renderSizeSelect(pricing) {
      let sizes = [];
      pricing.forEach(price => {
        if (price.size !== 'EMPTY' && (!price.hasStock || (price.hasStock && price.remainingStock))) {
          sizes.push({label: price.size, value: price.size});
        }
      });
      //console.log(sizes);
      if (sizes.length > 0) {
      return (<TextField
        id="quantity-select"
        select
        label="Size"
        value={this.props.itemInfo.size}
        onChange={this.handleChangeSize('size')}
        SelectProps={{
          native: true,
        }}
        helperText="Select a size"
        margin="normal"
        variant="outlined"
      >
        {sizes.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>);
      } else {
        return null;
      }
    }

    handleChangeQuantity = name => event => {
        //console.log(event.target.value);
      const indexOfItem = this.props.orderItems.findIndex((orderItem) => {
        return orderItem.item === this.props.itemInfo.item;
      });

      let newOrderItems = [...this.props.orderItems];
      //console.log({indexOfItem});
      if (indexOfItem >= 0) {

        //console.log('Value: ', event.target.value);
        if (parseInt(event.target.value) === 0) {
          //console.log('removing item: ', newOrderItems);
          newOrderItems.splice(indexOfItem, 1);
          //console.log('removed item: ', newOrderItems);
        } else {
          //console.log('value exists changing quantity');
          newOrderItems[indexOfItem].quantity = event.target.value;
        }
      } else {
        newOrderItems.push({
          item: this.props.itemInfo.item,
          quantity: event.target.value,
          size: this.state.size,
          isDairyFree: this.state.isDairyFree
        });
      }
      //console.log({newOrderItems});
      this.setState({ quantity: event.target.value });
      this.props.updateOrderItems(newOrderItems);
    };

    handleRemoveItem = event => {
        const indexOfItem = this.props.orderItems.findIndex((orderItem) => {
          return orderItem.item === this.props.itemInfo.item;
        });

        let newOrderItems = [...this.props.orderItems];

        if (indexOfItem >= 0) {
            newOrderItems.splice(indexOfItem, 1);
            //console.log('removed item: ', newOrderItems);
            //console.log({newOrderItems});
            this.props.updateOrderItems(newOrderItems);

            if (newOrderItems.length === 0) {
                this.setState({dialogOpen: false});
            }
        } 
    }

    handleChangeSize = name => event => {
      const indexOfItem = this.props.orderItems.findIndex((orderItem) => {
        return orderItem.item === this.props.itemInfo.item;
      });

      let newOrderItems = [...this.props.orderItems];

      if (indexOfItem >= 0) {
        newOrderItems[indexOfItem].size = event.target.value;
        this.setState({ size: event.target.value });
        this.props.updateOrderItems(newOrderItems);
      } 
    };

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });
    };

    handleDairyFreeChange = field => async event => {
        const indexOfItem = this.props.orderItems.findIndex((orderItem) => {
            return orderItem.item === this.props.itemInfo.item;
          });
    
          let newOrderItems = [...this.props.orderItems];
    
          if (indexOfItem >= 0) {
            newOrderItems[indexOfItem].isDairyFree = event.target.checked;
            this.setState({ isDairyFree: event.target.checked });
            this.props.updateOrderItems(newOrderItems);
          } 
      };

      handleGanacheToppingChange = field => async event => {
        const indexOfItem = this.props.orderItems.findIndex((orderItem) => {
            return orderItem.item === this.props.itemInfo.item;
          });
    
          let newOrderItems = [...this.props.orderItems];
    
          if (indexOfItem >= 0) {
            newOrderItems[indexOfItem].ganacheTopping = event.target.checked;
            this.setState({ ganacheTopping: event.target.checked });
            this.props.updateOrderItems(newOrderItems);
          } 
      };
  

    renderDairyFree(canBeDairyFree) {
        if (canBeDairyFree) {
          return (
        <FormControlLabel
        control={
          <Switch
            checked={this.props.itemInfo.isDairyFree}
            onChange={this.handleDairyFreeChange('isDairyFree')}
            value="checkedA"
          />
        }
        labelPlacement="start"
        label="Non-Dairy ($2 Extra)"
      />
          );
        }
  
        return null;
      }

      renderGanacheTopping(ganacheTopping) {
        if (ganacheTopping) {
          return (
        <FormControlLabel
        control={
          <Switch
            checked={this.props.itemInfo.ganacheTopping}
            onChange={this.handleGanacheToppingChange('ganacheTopping')}
            value="checkedA"
          />
        }
        labelPlacement="start"
        label="Ganache Topping"
      />
          );
        }
  
        return null;
      }

    render() {
      //const { orders } = this.props;
      const { classes, itemInfo } = this.props;
      const shouldRenderGanacheTopping = itemInfo.item === 'Cheesecake' ? true : false;
      const menuItem = this.props.menuItems.find(menuItem => {
        return menuItem.item === itemInfo.item;
      });
      //console.log(this.props);
      return (
        <div>
          <div className={classes.MenuItemBlock}>
          <div className={classes.ItemImage}>
            <S3Image imgKey={menuItem.imageName} theme={{ photoImg: { width: '100%' } }}/>
          </div>
          <div className={classes.MenuItemInfo}>
          <div className={classes.MenuItemTitleAndPrice}>
            <Typography align='left' color='primary' variant="h6" gutterBottom>
              {itemInfo.item}
            </Typography>
          </div>
          <div>
          </div>
          <div className={classes.QuantityAndSizeSelect}>
          <TextField
            id="quantity-select"
            select
            label="Quantity"
            value={itemInfo.quantity}
            onChange={this.handleChangeQuantity('quantity')}
            SelectProps={{
              native: true,
            }}
            helperText="Select a quantity"
            margin="normal"
            variant="outlined"
          >
            {quantities.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <div className={classes.QuantityAndSizeSpacing} />
          {this.renderSizeSelect(menuItem.pricing)}
          {this.renderDairyFree(menuItem.canBeDairyFree)}
          {this.renderGanacheTopping(shouldRenderGanacheTopping)}
          </div>
          <Button size="small" variant="contained" className={classes.DeleteItemButton} onClick={this.handleRemoveItem}>
          Remove Item
            </Button>
          </div>
          </div>
        </div>
      );
    }
  } // end render
  
  OrderItem.propTypes = {
    itemInfo: PropTypes.object.isRequired,
    //classes: PropTypes.object.isRequired,
    //orders: PropTypes.array.isRequired,
  };
  
  
  const mapStateToProps = ({state}) => {
    //console.log({state});
    return { orderItems: state.orderItems, menuItems: state.menuItems };
  };
  
  export default connect(mapStateToProps, { updateOrderItems })(withStyles(styles)(OrderItem));