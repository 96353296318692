/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://unehcovujjfo5phj2iay3quh24.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:683fc644-c2aa-4206-9c11-6ee688b403d1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gbHqaSKXt",
    "aws_user_pools_web_client_id": "563re8trspn970kg6pvvdoipvf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "dbbordering-20190304202728-hostingbucket-stage",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d23t65uzccglhx.cloudfront.net"
};


export default awsmobile;
